import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { SalesRulePromotionCopy } from "@catchoftheday/cart-components";
import { Flex, IconShopbagPlus, IconTickCircle } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SalesRulePromotionProgress = function SalesRulePromotionProgress(_ref) {
  var description = _ref.description,
      linkText = _ref.linkText,
      eventSlug = _ref.eventSlug,
      isApplied = _ref.isApplied,
      containerProps = _ref.containerProps;
  return ___EmotionJSX(Flex, _extends({
    color: isApplied ? "primaryDark" : "brand.secondaryDark",
    p: 2,
    borderRadius: 4,
    backgroundColor: isApplied ? "primaryLight" : "secondaryLight",
    alignItems: "center"
  }, containerProps), isApplied ? ___EmotionJSX(IconTickCircle, {
    "aria-label": "progress completed icon",
    color: "inherit",
    mr: 2,
    height: 16,
    width: 16,
    style: {
      flex: "none"
    }
  }) : ___EmotionJSX(IconShopbagPlus, {
    "aria-label": "progress ongoing icon",
    color: "inherit",
    mr: 2,
    height: 24,
    width: 24,
    style: {
      flex: "none"
    }
  }), ___EmotionJSX(SalesRulePromotionCopy, {
    description: description,
    linkText: linkText,
    eventSlug: eventSlug,
    containerProps: {
      fontSize: "base",
      color: "inherit"
    }
  }));
};
export default SalesRulePromotionProgress;