import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useContext, useEffect, useState } from "react";
import { useSponsoredProductRecommenderQuery } from "@personalisation/generated/graphql";
import { mapSponsoredProductsTracking, SNOWPLOW_SESSION_ID, SOURCE_TYPE_SPONSORED_RECOMMENDER } from "@catchoftheday/analytics";
import { CustomerContext } from "@catchoftheday/customer";
import { getEnvConfig } from "@catchoftheday/env-config";
import { SponsoredLabel } from "@catchoftheday/product-components";
import { RecommenderCarousel } from "../RecommenderCarousel";
import { getProductTiles, getRecommenderTrackingData } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
var recommenderSourceType = SOURCE_TYPE_SPONSORED_RECOMMENDER;
export var SponsoredProductRecommender = function SponsoredProductRecommender(_ref) {
  var _fromProduct$from_pro, _data$retailMediaProd, _data$retailMediaProd2;

  var offerId = _ref.offerId,
      category = _ref.category,
      fromProduct = _ref.fromProduct,
      sourceId = _ref.sourceId,
      newProductCard = _ref.newProductCard;

  var _useState = useState(),
      snowplowSessionId = _useState[0],
      setSnowplowSessionId = _useState[1];

  var _useContext = useContext(CustomerContext),
      customerData = _useContext.data,
      customerLoading = _useContext.loading;

  var TOTAL_PRODUCTS = Number(getEnvConfig().SPONSORED_PRODUCT_RECOMMENDER_NUM_RESULTS);
  useEffect(function () {
    var spId = sessionStorage.getItem(SNOWPLOW_SESSION_ID);

    if (spId) {
      setSnowplowSessionId(spId);
      return;
    } // Wait for snowplow session id to be set in session storage


    var checkSessionId = function checkSessionId(event) {
      if (event.key === SNOWPLOW_SESSION_ID) {
        setSnowplowSessionId(event.newValue);
      }
    };

    window.addEventListener("storage", checkSessionId);
    return function () {
      return window.removeEventListener("storage", checkSessionId);
    };
  }, []);

  var _useSponsoredProductR = useSponsoredProductRecommenderQuery({
    variables: {
      limit: TOTAL_PRODUCTS,
      inventoryId: "product_page_recommender",
      snowplowSessionId: snowplowSessionId,
      customerUUID: customerData === null || customerData === void 0 ? void 0 : customerData.metaData.customerUuid,
      offerId: offerId,
      category: category,
      pageId: "product",
      excludeProductIds: (_fromProduct$from_pro = fromProduct.from_product_ids) === null || _fromProduct$from_pro === void 0 ? void 0 : _fromProduct$from_pro.map(function (id) {
        return "".concat(id);
      })
    },
    skip: customerLoading || !snowplowSessionId
  }),
      data = _useSponsoredProductR.data,
      loading = _useSponsoredProductR.loading;

  if (!(data !== null && data !== void 0 && (_data$retailMediaProd = data.retailMediaProducts) !== null && _data$retailMediaProd !== void 0 && (_data$retailMediaProd2 = _data$retailMediaProd.products) !== null && _data$retailMediaProd2 !== void 0 && _data$retailMediaProd2.length)) {
    return null;
  }

  var products = data.retailMediaProducts.products.map(function (product) {
    return _objectSpread(_objectSpread({}, product), {}, {
      sourceType: recommenderSourceType,
      isSponsored: true,
      hideSponsoredLabel: true,
      linkToOffer: true
    });
  });
  var sponsoredItemsTracking = mapSponsoredProductsTracking(data === null || data === void 0 ? void 0 : data.retailMediaProducts.tracking);
  var tiles = getProductTiles({
    products: products,
    sourceType: recommenderSourceType,
    sourceId: sourceId,
    fromProduct: fromProduct,
    newProductCard: newProductCard,
    sponsoredItemsTracking: sponsoredItemsTracking
  });
  var trackingData = getRecommenderTrackingData(products, "product");
  return ___EmotionJSX(RecommenderCarousel, {
    tiles: tiles,
    heading: "Recommended products",
    subHeading: ___EmotionJSX(SponsoredLabel, {
      display: "block",
      textAlign: "center",
      mb: 4
    }),
    sourceType: recommenderSourceType,
    sourceId: sourceId,
    trackingData: trackingData,
    fromProduct: fromProduct,
    maxTiles: tiles.length,
    carouselProps: {
      disableNavButtons: {
        next: loading
      },
      touchEnabled: !loading
    },
    sponsoredTrackingData: sponsoredItemsTracking
  });
};